.self-sign-up {
  margin: 0;
  overflow: visible;
  padding: 0 24px 200px
}

.self-sign-up .react-phone-number-input__country--native {
  left: -.5em
}

.self-sign-up .react-phone-number-input__icon {
  height: 1.5em
}

@media (min-width: 992px) {
  .self-sign-up {
      padding:reset
  }
}

.self-sign-up .services_page-headings {
  font-size: 28px
}

@media (min-width: 992px) {
  .self-sign-up .services_page-headings {
      font-size:38px
  }
}

.self-sign-up .apply-now-input {
  border: transparent;
  margin: 0
}

.self-sign-up .input-available {
  padding-right: 90px
}

.self-sign-up .error-field {
  border: 1px solid #f25d5d
}

.self-sign-up .error-field-checkbox {
  outline: 1px solid #f25d5d
}

.self-sign-up .apply-now-fields {
  margin: 28px auto;
  max-width: 325px
}

@media (min-width: 992px) {
  .self-sign-up .apply-now-fields {
      width:calc(50% - 30px);
      margin: 48px 28px 0 0;
      display: inline-block
  }

  .self-sign-up .apply-now-fields p {
      position: absolute;
      top: 48px
  }
}

.self-sign-up .center-align {
  margin: 20px 0
}

.self-sign-up .agree-receive {
  color: #3f3f3f;
  font-size: 12px
}

.self-sign-up .privacy-link {
  color: #2f81e4
}

.self-sign-up .domain-link {
  border: none;
  margin: 0 30px 0 0
}

@media (min-width: 992px) {
  .self-sign-up .domain-link {
      float:right;
      left: 50%;
      padding: 8px 0
  }
}

.self-sign-up .services_page-headings {
  position: relative;
  margin-top: 0;
  padding-top: 30px;
  font-size: 18px
}

@media (min-width: 992px) {
  .self-sign-up .services_page-headings {
      font-size:32px
  }
}

.self-sign-up .services_page-headings:after {
  content: "";
  position: absolute;
  height: 6px;
  width: 80px;
  line-height: 1.25
}

@media (min-width: 992px) {
  .self-sign-up .services_page-headings:after {
      width:100px;
      left: calc(50% - 45px)
  }
}

.self-sign-up .contact-us-button-publishing {
  margin: 0 auto 100px;
  top: 18px;
  bottom: 20px;
  height: 64px
}

.self-sign-up .checkbox-wrapper {
  width: 100%;
  border: none;
  margin: 0;
  padding: 5px 0
}

.self-sign-up .checkbox {
  position: relative;
  top: 0;
  margin: 0 9px 0 0;
  color: #757575
}

.self-sign-up .one-col-new {
  margin: 0
}

.self-sign-up .checkbox-container {
  max-width: 200px
}

@media (min-width: 992px) {
  .self-sign-up .checkbox-container {
      margin:0 auto;
      text-align: center
  }
}

.self-sign-up .checkbox-container p {
  font-size: 13px;
  color: rgba(255,0,0,.81)
}

.self-sign-up .checkbox-invalid-error {
  color: #f25d5d;
  position: relative;
  top: 6px;
  left: 15px
}

.self-sign-up .domain-name {
  color: #888;
  float: left;
  font-size: 12px;
  display: inherit;
  margin: 1em 0;
  width: 100%
}

@media (min-width: 992px) {
  .self-sign-up .domain-name {
      padding-top:7px;
      margin: 0;
      float: left;
      font-size: 12px;
      display: inline-block
  }
}

.self-sign-up .domain-link {
  padding: 0
}

@media (min-width: 992px) {
  .self-sign-up .domain-link {
      padding:8px 0
  }
}

.self-sign-up .domain-url {
  margin: 0;
  display: inline-block;
  color: #656464!important;
  font-weight: 800
}

.self-sign-up .invalid-error {
  color: red;
  font-size: 14px
}

.self-sign-up .invalid-error-special-characters {
  color: red;
  font-size: 11px;
  float: left;
  margin: 1em 0
}

@media (min-width: 992px) {
  .self-sign-up .invalid-error-special-characters {
      color:red;
      font-size: 11px
  }
}

.self-sign-up .spinner-loader {
  position: absolute;
  right: 2%;
  top: 25%;
  font-size: 14px;
  width: 90px;
  text-align: right;
  padding-top: 1px
}

.self-sign-up .invalid-error-unavail {
  color: #f25d5d
}

.self-sign-up .invalid-error-unavail,.self-sign-up .success-mesg {
  position: absolute;
  right: 5%;
  top: 35%;
  font-size: 14px;
  width: 90px;
  text-align: right
}

.self-sign-up .success-mesg {
  color: #217a21
}


/* spinner styles */

.spinner-wrapper {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 99;
  opacity: .5;
  overflow: hidden;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain
}

.large-spinner {
  position: fixed;
  width: 100%
}

.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite
}

@-webkit-keyframes rotator {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg)
  }
}

@keyframes rotator {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg)
  }
}

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite,colors 5.6s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite,colors 5.6s ease-in-out infinite
}

@-webkit-keyframes colors {
  0% {
      stroke: #2f81e4
  }

  25% {
      stroke: #2f81e4
  }

  50% {
      stroke: #2f81e4
  }

  75% {
      stroke: #2f81e4
  }

  to {
      stroke: #2f81e4
  }
}

@keyframes colors {
  0% {
      stroke: #2f81e4
  }

  25% {
      stroke: #2f81e4
  }

  50% {
      stroke: #2f81e4
  }

  75% {
      stroke: #2f81e4
  }

  to {
      stroke: #2f81e4
  }
}

@-webkit-keyframes dash {
  0% {
      stroke-dashoffset: 187
  }

  50% {
      stroke-dashoffset: 46.75;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg)
  }

  to {
      stroke-dashoffset: 187;
      -webkit-transform: rotate(450deg);
      transform: rotate(450deg)
  }
}

@keyframes dash {
  0% {
      stroke-dashoffset: 187
  }

  50% {
      stroke-dashoffset: 46.75;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg)
  }

  to {
      stroke-dashoffset: 187;
      -webkit-transform: rotate(450deg);
      transform: rotate(450deg)
  }
}


.confirm-sign-up {
  min-height: 150px
}

@media (min-width: 992px) {
  .confirm-sign-up {
      min-height:300px
  }
}

.services_page-feature {
  border-top: 1px solid rgba(148,161,171,.5)
}

@media (min-width: 992px) {
  .services_page-feature {
      max-width:1200px;
      margin: 0 auto;
      text-align: center
  }
}
.self-sign-up .services_page-headings {
  font-size: 28px
}

@media (min-width: 992px) {
  .self-sign-up .services_page-headings {
      font-size:38px
  }
}


.self-sign-up .services_page-headings {
  position: relative;
  margin-top: 0;
  padding-top: 30px;
  font-size: 18px
}

@media (min-width: 992px) {
  .self-sign-up .services_page-headings {
      font-size:32px
  }
}

.self-sign-up .services_page-headings:after {
  content: "";
  position: absolute;
  height: 6px;
  width: 80px;
  line-height: 1.25
}

@media (min-width: 992px) {
  .self-sign-up .services_page-headings:after {
      width:100px;
      left: calc(50% - 45px)
  }
}

.services_page-headings {
  position: relative;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.25;
  padding-bottom: 15px;
  text-align: center
}

.services_page-headings:after {
  content: " ";
  position: absolute;
  bottom: 0;
  height: 6px;
  background-color: #2f81e4;
  width: 80px;
  top: 6;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

@media (min-width: 992px) {
  .services_page-headings:after {
      width:100px;
      left: calc(50% - 50px);
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@media (min-width: 992px) {
  .services_page-headings {
      font-size:32px
  }
}

.trial-thank-you .trial-cta {
  display: flex;
  justify-content: center;
  margin: 20px 0 25px
}

.trial-thank-you .trial-cta .contact-us-button-publishing {
  margin: 0 15px
}

.trial-cta {
  padding-bottom: 50px
}


.services_page-description {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
  text-align: center
}

@media (min-width: 992px) {
  .services_page-description {
      font-size:18px;
      max-width: 650px;
      margin: 0 auto;
      text-align: center
  }
}
