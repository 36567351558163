/*ending cs for custom style*/
.react-phone-input-width-cs {
  width: 100% !important;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, “Segoe UI”,
    “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”,
    “Helvetica Neue”, sans-serif;
  color: #343e3c;
  height: 45px !important;
  border: 1px solid #e3e3e3 !important;

}


/* should always come below react-phone-input-width-cs selector */
.react-phone-input-red-cs {
 border: 1px solid #f65a5b !important;
}

.flag-dropdown {
  border: 1px solid #e3e3e3 !important;
}

.react-phone-input-dropdown-cs {
    border-top-color: #f65a5b !important;
    border-top-style: solid !important;
    border-top-width: 1px !important;
    border-left-color: #f65a5b !important;
    border-left-style: solid !important;
    border-left-width: 1px !important;
    border-bottom-color: #f65a5b !important;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;

}

.react-phone-input-container-mb-cs {
  margin-bottom: 24px;
}

